import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import SendIcon from '@material-ui/icons/Send';
import clsx from 'clsx';
import LinkButton from 'components/buttons/link-button/link-button';
import { OperationsModalMessageActions } from 'entities/ccb-operation/ccb-operation';
import { ImportedOperationStatus } from 'model/enums/imported-operation-status';
import { OperationFilterRequest } from 'model/imported-operation';
import { useTranslation } from 'react-i18next';
import './operation-slide.scss';

interface OperationSlideProps {
    setModalMessage: (value: OperationsModalMessageActions | undefined) => void;
    selectedOperationsAmount: number;
    onCancel?: () => void;
    activeOperationStatus: ImportedOperationStatus | undefined;
    isSelectedAllCheckbox: boolean;
    filterActive: OperationFilterRequest;
}

const I18N_PREFIX = 'pages.scd.ccb-operation';

const OperationSlide = (props: OperationSlideProps) => {
    const { setModalMessage, selectedOperationsAmount, onCancel, activeOperationStatus, isSelectedAllCheckbox, filterActive } = props;

    const { t } = useTranslation();

    const disableArchiveAction =
        selectedOperationsAmount === 0 ||
        (!!activeOperationStatus &&
            activeOperationStatus !== ImportedOperationStatus.BATCHLESS &&
            activeOperationStatus !== ImportedOperationStatus.NEW &&
            activeOperationStatus !== ImportedOperationStatus.PRE_PROCESS_ERROR &&
            activeOperationStatus !== ImportedOperationStatus.DEFINE_FLOW_ERROR &&
            activeOperationStatus !== ImportedOperationStatus.DEFINE_PAYMENT_ERROR &&
            activeOperationStatus !== ImportedOperationStatus.AUTOMATIC_PAYMENT_ERROR &&
            !isSelectedAllCheckbox);

    return (
        <div className="scd-operation-slide">
            <div className="scd-operation-slide--container">
                <div className="scd-operation-slide--content">
                    <span
                        className="scd-operation-slide--item__action"
                        onClick={() => (onCancel ? onCancel() : setModalMessage(OperationsModalMessageActions.CANCEL))}
                    >
                        {t('entity.action.cancel').toLocaleUpperCase()}
                    </span>
                </div>
                <div className="scd-operation-slide--content__selected-operations">
                    {t(`${I18N_PREFIX}.selected-operation-per-page`, {
                        selectedOperationsAmount,
                        count: selectedOperationsAmount,
                    }).toLocaleLowerCase()}
                </div>
                <div className="scd-operation-slide--content">
                    <span
                        className={clsx('scd-operation-slide--item__action', {
                            disabled: disableArchiveAction,
                        })}
                        onClick={() => (disableArchiveAction ? undefined : setModalMessage(OperationsModalMessageActions.ARCHIVE_MANY))}
                    >
                        <MoveToInboxIcon /> {t('entity.action.archive').toLocaleUpperCase()}
                    </span>

                    <span style={{ width: '145px', marginRight: '30px' }}>
                        <LinkButton
                            label={t('entity.action.reexport-webcred')}
                            onClick={() => setModalMessage(OperationsModalMessageActions.REEXPORT_ALL_OPERATIONS)}
                            startIcon={<SendIcon />}
                            disabled={
                                (activeOperationStatus !== ImportedOperationStatus.EXPORTED && !isSelectedAllCheckbox) ||
                                (isSelectedAllCheckbox &&
                                    filterActive.status?.length !== 0 &&
                                    filterActive.status?.every(status => status !== ImportedOperationStatus.EXPORTED))
                            }
                            outlinedStyle
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default OperationSlide;
