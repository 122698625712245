export enum ErrorType {
    NON_BLOCKING = 'NON_BLOCKING',
    BLOCKING = 'BLOCKING',
    IGNORED = 'IGNORED',
}

export enum GiroScdErrorConstants {
    AMENDMENT_TICKET_WITH_MULTIPLE_ORGANIZATIONS = 'error.amendmentTicket.multipleOrganizations',
    AMENDMENT_TICKET_WITH_NO_OPERATIONS = 'error.amendmentTicket.noOperations',
    AMENDMENT_TICKET_NOT_FOUND = 'error.amendmentTicket.notFound',
    AMENDMENT_TICKET_EXPIRED = 'error.amendmentTicket.expired',
    AMENDMENT_TICKET_AMENDED = 'error.amendmentTicket.amended',
    AMENDMENT_TICKET_ARCHIVED = 'error.amendmentTicket.archived',
    AMENDMENT_TICKET_DISPOSED_NOT_FOUND = 'error.amendmentTicketDisposed.notFound',
    AUTHORITY_NOT_FOUND = 'error.authority.notFound',
    BANK_NOT_FOUND = 'error.bank.notFound',
    BANK_IDENTIFICATION_MISSING = 'error.bank.identificationMissing',
    BANK_ACCOUNT_INVALID_FAVORED = 'error.bankAccount.invalidFavored',
    BANKING_HASH_ALREADY_FORMALIZED = 'error.bankingHash.alreadyFormalized',
    BATCH_NOT_FOUND = 'error.batch.notFound',
    BATCH_CLOSE = 'error.batch.close',
    BATCH_ORIGINATOR_DOCUMENT_UPLOAD_TYPE_NOT_CONFIGURED = 'error.batch.originatorUploadDocumentTypeNotConfigured',
    BATCH_ORIGINATOR_DOCUMENT_TYPE_NOT_CONFIGURED = 'error.batch.originatorDocumentTypeNotConfigured',
    BATCH_OPERATION_WRONG_PRODUCT = 'error.batch.operationWrongProduct',
    BATCH_DOCUMENT_OPERATION_MISSING_ASSET_SUB_TYPE = 'error.batchDocument.operationMissingAssetSubType',
    BATCH_OPERATION_REMOVAL_AUTO_PAYMENT = 'error.batch.operationRemovalWithAutomaticPayment',
    BATCH_OPERATION_REMOVAL_WITH_PAYMENT = 'error.batch.operationRemovalWithPayment',
    BATCH_OPERATION_NOT_IN_BATCH = 'error.batch.operationNotInBatch',
    BATCH_OPERATIONS_ORGANIZATION_CONFLICT = 'error.batchOperations.organizationConflict',
    BATCH_OPERATIONS_PRODUCT_CONFLICT = 'error.batchOperations.productConflict',
    BATCH_OPERATIONS_ORIGINATOR_CONFLICT = 'error.batchOperations.originatorConflict',
    BATCH_OPERATIONS_PROGRAm_CONFLICT = 'error.batchOperations.programConflict',
    BATCH_SIGNATURE_ALREADY_EXPORTED = 'error.batchSignature.alreadyExported',
    CATEGORIZATION_FAILURE_DONT_MAPPED_TO_FLOW_FAILURE = 'error.categorizationFailure.flowNotSupported',
    CATEGORIZATION_FAILURE_DONT_MAPPED_TO_PAYMENT_TYPE = 'error.categorizationFailure.paymentTypeNotSupported',
    CATEGORIZATION_FAILURE_DONT_MAPPED_TO_INSTITUTION = 'error.categorizationFailure.institutionNotSupported',
    CATEGORIZATION_FAILURE_DONT_MAPPED_TO_FAVORED_TYPE = 'error.categorizationFailure.favoredTypeNotSupported',
    CATEGORIZATION_FAILURE_DONT_MAPPED_TO_FLOW_TYPE = 'error.categorizationFailure.flowTypeNotSupported',
    CELCOIN_PIX_SEARCH_FAILURE = 'error.celcoin.pixKeySearchFailure',
    CELCOIN_PAYMENT_CODE_NOT_INFORMED = 'error.celcoin.paymentCodeNotInformed',
    CELCOIN_PAYMENT_CODE_ALREADY_PAID = 'error.celcoin.paymentAlreadyPaid',
    CELCOIN_PAYMENT_UNKNOWN_CODE = 'error.celcoin.paymentCodeUnknown',
    COMPANY_ITEM_MISSING_COMPANY = 'error.companyItem.missingCompany',
    COMPANY_GROUP_WRONG_ORGANIZATION = 'error.companyGroup.wrongOrganization',
    COMPANY_ALREADY_EXIST = 'error.company.alreadyExist',
    COMPANY_IDENTIFICATION_INVALID = 'error.company.invalidIdentification',
    COMPANY_PERSON_TYPE_INVALID = 'error.company.personTypeInvalid',
    COMPANY_GROUP_ALREADY_EXISTS_IN_PROGRAM = 'error.companyGroup.alreadyExistsInProgram',
    CONTRACT_OPERATION_ID_ALREADY_EXISTS = 'error.importedOperation.contractOperationIdAlreadyExist',
    CNAB_ABA_YEAR_NOT_FOUND = 'error.cnab.abaYearNotFound',
    CNAB_TEMPLATE_NOT_GENERATED = 'error.cnabTemplate.notGenerated',
    CNAB_NOT_FOUND = 'error.cnab.notFound',
    CNAB_EXPIRATION_NUMBER_MISSING = 'error.cnab.expirationNumberMissing',
    CNAB_PARTICIPANT_CONTROL_EXCEEDS_LIMIT = 'error.cnab.participantControlExceedsLimit',
    DEBTOR_ACCOUNT_NOT_FOUND = 'error.debtorAccount.notFound',
    DEPRECATED_ACTION = 'error.action.deprecated',
    DISBURSEMENT_ACCOUNT_IS_REQUIRED_FOR_AMENDMENT = 'error.importedBanking.disbursementAccountIsRequiredForAmendment',
    DISBURSEMENT_AMENDMENT_POLICY_NOT_SUPPORTED = 'error.policy.disbursementAmendmentPolicyNotSupported',
    ENTITY_NOT_FOUND = 'error.entity.id.notFound',
    IMPORTED_BANK_ACCOUNT_NOT_FOUND = 'error.importedBankAccount.notFound',
    IMPORTED_BANK_ACCOUNT_FAVORED_DOES_NOT_MATCH_DEBTOR = 'error.importedBankAccount.favoredDoesNotMatchDebtor',
    IMPORTED_BANKING_NOT_FOUND = 'error.importedBanking.notFound',
    IMPORTED_BANKING_TYPE_NOT_SUPPORTED = 'error.importedBanking.typeNotSupported',
    IMPORTED_OPERATION_MISSING_DEBTOR_IDENTIFICATION = 'error.importedOperation.missingDebtorIdentification',
    IMPORTED_OPERATION_ARCHIVE_PAYMENT_IN_PROGRESS = 'error.importedOperation.archivingWithPaymentInProgress',
    IMPORTED_OPERATION_ARCHIVE_PAYMENT_CONCLUDED = 'error.importedOperation.archivingWithPaymentConcluded',
    IMPORTED_INSTALLMENT_GENERATE_DOCUMENT_NUMBER_ERROR = 'error.importedInstallment.documentNumberGenerateError',
    INTEGRATION_PIX_KEY_FAILURE = 'error.integration.pixKeyFailure',
    INTEGRATION_AUTHENTICATION_TOKEN_RECOVERY_FAILED = 'error.integrationAuthentication.tokenRecoveryFailed',
    INTEGRATION_AUTHENTICATION_ACCOUNT_TYPE_NOT_SUPPORTED = 'error.integrationAuthentication.accountTypeNotSupported',
    INTEGRATION_AUTHENTICATION_MISSING_ACCOUNT_HEADER = 'error.integrationAuthentication.missingAccountHeader',
    INTEGRATION_AUTHENTICATION_DIGITAL_ACCOUNT_REFERENCE_REQUIRED = 'error.integrationAuthentication.digitalAccountReferenceRequired',
    INTEGRATION_AUTHENTICATION_UNRESOLVED = 'error.integrationAuthentication.unresolved',
    INTEGRATION_NOT_SUPPORTED = 'error.integration.notSupported',
    IPOC_INFORMATION_NOT_PRESENT = 'error.ipoc.informationNotPresent',
    IPOC_INFORMATION_SIZE_NOT_MATCH = 'error.ipoc.informationSizeNotMatch',
    ISSUER_NOT_MATCH_IDENTIFICATION = 'error.issuer.notMatchIdentification',
    ISSUER_POLICY_ACTIVE_ALREADY_EXIST = 'error.issuerPolicy.activeAlreadyExist',
    ISSUER_POLICY_NOT_FOUND = 'error.issuerPolicy.notFound',
    ISSUER_POLICY_TYPE_NOT_SUPPORTED = 'error.issuerPolicy.typeNotSupported',
    ISSUER_POLICY_INTEGRATION_REQUIRED_PLATFORM = 'error.issuerPolicy.integrationRequiredPlatform',
    LOAN_SIMULATION_NOT_FOUND = 'error.loanSimulation.notFound',
    LOAN_SIMULATION_ALREADY_USED = 'error.loanSimulation.AlreadyUsed',
    MONTHLY_COMMISSION_DOCUMENT_ALREADY_EXISTS = 'error.monthlyCommissionDocument.alreadyExists',
    MONTHLY_COMMISSION_MONTH_NOT_CLOSED = 'error.monthlyCommission.monthNotClosed',
    MONTHLY_COMISSION_NOT_FOUND = 'error.monthlyCommission.notFound',
    OPERATION_MISSING_ORIGINATOR = 'error.importedOperation.missingOriginator',
    OPERATION_BOUND_TO_BATCH = 'error.importedOperation.boundToBatch',
    OPERATION_WRONG_GROUP = 'error.importedOperation.wrongGroup',
    OPERATION_WRONG_STATUS = 'error.importedOperation.wrongStatus',
    OPERATION_ALREADY_EXPORTED = 'error.operation_already_exported',
    OPERATION_COMPANY_IDENTIFICATION_ABSENT = 'error.importedOperation.companyIdentificationAbsent',
    OPERATION_REEXPORT_SIMULATION_EXCEPTION = 'error.importedOperation.reExportSimulationException',
    OPERATION_REEXPORT_IMPORT_EXCEPTION = 'error.importedOperation.reExportImportException',
    OPERATION_REEXPORT_EMPTY_SIMULATION = 'error.importedOperation.reExportEmptySimulation',
    OPERATION_MISSING_DISBURSEMENT = 'error.importedOperation.missingDisbursement',
    OPERATION_MISSING_BANK_ACCOUNT = 'error.importedOperation.missingBankAccount',
    OPERATION_MISSING_PIX = 'error.importedOperation.missingPix',
    OPERATION_PERSON_TYPE_CONFLICT = 'error.importedOperation.personTypeConflict',
    ORIGINATOR_IDENTIFICATION_ALREADY_EXISTS = 'error.originator.identificationAlreadyExists',
    ORIGINATOR_NOT_FOUND_BY_PAYMENT_VALUE = 'error.originator.notFoundByPaymentValue',
    ORIGINATOR_NOT_FOUND_BY_MONTHLY_COMMISSION = 'error.originator.notFoundByMonthlyCommission',
    ORIGINATOR_PAYMENT_INSTITUTION_NOT_FOUND = 'error.originatorPaymentInstitution.notFound',
    ORIGINATOR_PAYMENT_INSTITUTION_MUST_HAVE_ONLY_ONE_ACTIVE = 'error.originatorPaymentInstitution.mustHaveOnlyOneActive',
    ORIGINATOR_PAYMENT_INSTITUTION_WITH_TECHNICAL_SUPPLIER_ALREADY_EXISTS = 'error.originatorPaymentInstitution.technicalSupplierAlreadyExists',
    ORIGINATOR_PAYMENT_INSTITUTION_HAVE_DUPLICATE_TECHNICAL_SUPPLIER = 'error.originatorPaymentInstitution.duplicateTechnicalSupplier',
    ORIGINATOR_WRONG_ORGANIZATION = 'error.originator.wrongOrganization',
    ORIGINATOR_PAYMENT_INSTITUTION_ACCOUNT_NOT_FOUND = 'error.originator.paymentInstitutionAccountNotFound',
    ORIGINATOR_NOT_FOUND_BY_PAYMENT_INSTITUTION_ACCOUNT = 'error.originator.notFoundByPaymentInstitutionAccount',
    ORIGINATOR_PAYMENT_INSTITUTION_EXCLUSIVE_TYPE_HAVE_ANOTHER_ORIGINATOR = 'error.originatorPaymentInstitution.exclusiveAccountOriginatorDoesNotMatch',
    PAYMENT_ACCOUNT_RECIPIENT_MISSING_BANK_IDENTIFICATION = 'error.paymentAccountRecipient.missingBankIdentification',
    PAYMENT_ACCOUNT_RECIPIENT_MISSING_AGENCY_NUMBER = 'error.paymentAccountRecipient.missingAgencyNumber',
    PAYMENT_ACCOUNT_RECIPIENT_MISSING_ACCOUNT_NUMBER = 'error.paymentAccountRecipient.missingAccoutNumber',
    PAYMENT_ACCOUNT_RECIPIENT_MISSING_ACCOUNT_TYPE = 'error.paymentAccountRecipient.missingAccoutType',
    PAYMENT_ACCOUNT_RECIPIENT_MISSING_PIX_KEY = 'error.paymentAccountRecipient.missingPixKey',
    PAYMENT_BANKING_ALREADY_EXISTS = 'error.paymentBanking.alreadyExists',
    PAYMENT_BANKING_MISSING_ACCOUNT = 'error.paymentBanking.missingAccount',
    PAYMENT_BANKING_MISSING_PIX = 'error.paymentBanking.missingPix',
    PAYMENT_BANKING_NOT_FOUND = 'error.paymentBanking.notFound',
    PAYMENT_BANKING_TYPE_NOT_SUPPORTED = 'error.paymentBanking.typeNotSupported',
    PAYMENT_BANKING_WRONG_ORIGIN = 'error.paymentBanking.wrongOrigin',
    PAYMENT_INSTITUTION_ACCOUNT_NOT_FOUND = 'error.paymentInstitutionAccount.notFound',
    PAYMENT_INSTITUTION_ACCOUNT_ALREADY_EXISTS = 'error.paymentInstitutionAccount.alreadyExists',
    PAYMENT_INSTITUTION_ACCOUNT_EXTERNAL_REFERENCE_REQUIRED = 'error.paymentInstitutionAccount.externalReferenceRequired',
    PAYMENT_INSTITUTION_ACCOUNT_CONVERT_NOT_SUPPORTED_FOR_TYPE = 'error.paymentInstitutionAccount.convertNotSupportedForType',
    PAYMENT_INSTITUTION_ACCOUNT_ALREADY_BOUND_TO_ORIGINATOR = 'error.paymentInstitutionAccount.accountAlreadyBoundToOriginator',
    PAYMENT_INSTITUTION_ACCOUNT_ALREADY_BOUND_TO_ISSUER = 'error.paymentInstitutionAccount.accountAlreadyBoundToIssuer',
    PAYMENT_INSTITUTION_ACCOUNT_TYPE_CONVERSION_BLOCKED_DUE_TO_TRANSACTIONS_INCONSISTENCY = 'error.paymentInstitutionAccount.typeConversionBlockedDueToTransactionsInconsistencies',
    PAYMENT_INSTITUTION_ACCOUNT_TYPE_CONVERSION_BLOCKED_DUE_TO_CONFIGURATION_INCONSISTENCY = 'error.paymentInstitutionAccount.typeConversionBlockedDueToConfigurationInconsistencies',
    PAYMENT_INSTITUTION_ACCOUNT_EXCLUSIVE_TYPE_NOT_SUPPORTED = 'error.paymentInstitutionAccount.exclusiveTypeNotSupported',
    PAYMENT_INSTITUTION_ACCOUNT_NOT_BOUND_TO_ORIGINATOR = 'error.paymentInstitutionAccount.notBoundToOriginator',
    PAYMENT_INSTITUTION_ACCOUNT_WITHDRAW_NOT_PROCESSED = 'error.paymentInstitutionAccount.withdrawNotProcessed',
    PAYMENT_INSTITUTION_ACCOUNT_NOT_HAVE_ORIGINATOR = 'error.paymentInstitutionAccount.notHaveOriginator',
    PAYMENT_INSTITUTION_ACCOUNT_NOT_HAVE_ISSUER = 'error.paymentInstitutionAccount.notHaveIssuer',
    PAYMENT_INSTITUTION_ACCOUNT_NOT_ACTIVE = 'error.paymentInstitutionAccount.notActive',
    PAYMENT_INSTITUTION_DEFAULT_NOT_FOUND = 'error.paymentInstitution.defaultNotFound',
    PAYMENT_INSTITUTION_FOUND_MULTIPLE_DEFAULT = 'error.paymentInstitution.foundMultipleDefault',
    PAYMENT_INSTITUTION_NOT_FOUND_BY_ORIGINATOR = 'error.paymentInstitution.notFountByOriginator',
    PAYMENT_INSTITUTION_FOUND_MULTIPLE_BY_ORIGINATOR = 'error.paymentInstitution.foundMultipleByOriginator',
    PAYMENT_INSTITUTION_NOT_SUPPORTED = 'error.paymentInstitution.notSupported',
    PAYMENT_INSTITUTION_REFERENCE_NOT_FOUND = 'error.paymentInstitution.referenceNotFound',
    PAYMENT_INSTITUTION_NOT_FOUND = 'error.paymentInstitution.notFound',
    PAYMENT_INSTITUTION_DID_NOT_PROCESS_PAYMENT = 'error.paymentInstitution.didNotProcessPayment',
    PAYMENT_INTEGRATION_TIMEOUT = 'error.paymentIntegration.timeout',
    PAYMENT_FAVORED_MISSING_IDENTIFICATION = 'error.paymentFavored.missingIdentification',
    PAYMENT_TRANSACTION_ACCOUNT_SOURCE_NOT_SUPPORTED = 'error.paymentTransaction.accountSourceNotSupported',
    PAYMENT_TRANSACTION_ACCOUNT_BANK_MISSING_IDENTIFICATION = 'error.paymentTransaction.accountBankMissingIdentification',
    PAYMENT_TRANSACTION_TYPE_NOT_SUPPORTED = 'error.paymentTransaction.typeNotSupported',
    PAYMENT_TRANSACTION_ORIGINATOR_NOT_DEFINED = 'error.paymentTransaction.originatorNotDefined',
    PAYMENT_TRANSACTION_INSTITUTION_ACCOUNT_NOT_DEFINED = 'error.paymentTransaction.institutionAccountNotDefined',
    PAYMENT_TRANSACTION_PIX_KEY_NOT_FOUND = 'error.paymentTransaction.pixKeyNotFound',
    PAYMENT_TRANSACTION_FAILURE_NOT_FOUND = 'error.paymentTransactionFailure.notFound',
    PAYMENT_TRANSACTION_FAVORED_NOT_DEFINED = 'error.paymentTransaction.favoredNotDefined',
    PAYMENT_TRANSACTION_EXCEEDS_MIN_VALUE = 'error.paymentTransaction.exceedsMinValue',
    PAYMENT_TRANSACTION_EXCEEDS_MAX_VALUE = 'error.paymentTransaction.exceedsMaxValue',
    PAYMENT_TRANSACTION_FAVORED_CONFLICT = 'error.paymentTransaction.favoredConflit',
    PAYMENT_TRANSACTION_UNRESOLVED = 'error.paymentTransaction.unresolved',
    PAYMENT_VALUE_NOT_FOUND = 'error.paymentValue.notFound',
    PAYMENT_VALUE_FAVORED_TYPE_WRONG_TYPE = 'error.paymentValueFavoredType.wrongType',
    PAYMENT_VALUE_WRONG_TYPE = 'error.paymentValue.wrongType',
    PERSON_IDENTIFICATION_INFERIMENT_ERROR = 'error.personIdentification.canNotBeInferred',
    PIX_KEY_TYPE_CONFLICT = 'error.pixKeyType.conflict',
    POLICY_ASCERTAIN_PAYMENT_ACCOUNT_DESTINATION_NOT_SUPPORTED = 'error.policy.ascertainPaymentAccountDestinationNotSupported',
    POLICY_ASCERTAIN_PAYMENT_ACCOUNT_SOURCE_NOT_SUPPORTED = 'error.policy.ascertainPaymentAccountSourceNotSupported',
    PROGRAM_WITH_PRODUCT_OR_ORIGINATOR_ALREADY_EXISTS = 'error.scdProgram.productOrOriginatorAlreadyExists',
    PROGRAM_MISSING_ORIGINATOR = 'error.scdProgram.missingOriginator',
    PROGRAM_WITH_IDENTIFIER_ALREADY_EXISTS = 'error.scdProgram.identifierAlreadyExists',
    PROGRAM_POLICY_NOT_FOUND = 'error.scdProgramPolicy.notFound',
    PROGRAM_MISSING_POLICY = 'error.scdProgram.missingPolicy',
    PROGRAM_MISSING_PAYMENT_FAVORED_POLICY = 'error.scdProgram.missingPaymentFavoredPolicy',
    PROGRAM_OUTPUT_POLICY_NOT_SUPPORTED = 'error.programOutputPolicy.notSupported',
    SIMULATOR_API_FAILED = 'error.simulator.simulatorApiFailed',
    TAX_NOT_FOUND = 'error.tax.notFound',
    TAX_VALUE_DOCUMENT_ALREADY_EXISTS = 'error.taxValueDocument.alreadyExists',
    TAX_TYPE_NOT_DEFINED = 'error.taxType.notDefined',
    TECHNICAL_SUPPLIER_NOT_ACTIVE = 'error.technicalSupplier.notActive',
    TECHNICAL_SUPPLIER_NOT_PAYMENT_INSTITUTION = 'error.technicalSupplier.notPaymentInstitution',
    TECHNICAL_SUPPLIER_NOT_FOUND = 'error.technicalSupplier.notFound',
    TECHNICAL_SUPPLIER_TYPE_NOT_FOUND = 'error.technicalSupplier.typeNotFound',
    TECHNICAL_SUPPLIER_INTERNAL_REFERENCE_ALREADY_EXISTS = 'error.technicalSupplier.alreadyExists',
    TECHNICAL_SUPPLIER_MISSING_ACCOUNT_TYPE = 'error.technicalSupplier.missingAccountType',
    TECHNICAL_SUPPLIER_MISSING_DEBTOR_ACCOUNT = 'error.technicalSupplier.missingDebtorAccount',
    TRANSFEERA_WITHDRAW_BALANCE_NOT_PROCESSED = 'error.transfeera.withdrawBalanceNotProcessed',
    USER_EMAIL_ALREADY_EXISTS = 'error.user.emailAlreadyExists',
    USER_NOT_FOUND = 'error.user.noteFound',
}

export enum GiroWebappErrorConstants {
    // http
    HTTP_UNAUTHORIZED = 'error.http.unauthorized',
    HTTP_FORBIDDEN = 'error.http.forbidden',

    // user
    USER_NOT_ACTIVATED = 'error.user.notActivated',

    // backing
    OPERATION_ALREADY_HAS_DOCUMENT = 'error.provisional.operation.alreadyHasDocument',
    FILE_DOES_NOT_MATCH_ANY_OPERATION = 'error.provisional.file.doesNotMatchAnyOperation',
    FILE_HAS_MULTIPLE_MATCHES = 'error.provisional.file.hasMultipleMatches',
}

export enum GTBankerSignDocuments {
    FALLBACK_ERROR = 'error.signDocument.unexpectedError',
    BATCH_SIGNATURE_NOT_FOUND = 'error.batchSignature.notFound',
    BATCH_SIGNATURE_UNSUPPORTED_TYPE = 'error.batchSignature.typeNotSupported',
    BATCH_SIGNATURE_UNSUPPORTED_ISSUER = 'error.batchSignature.issuerNotSupported',
    BATCH_SIGNATURE_UNSUPPORTED_PLATFORM = 'error.batchSignature.platformNotSupported',
    BATCH_OUTPUT_UNSUPPORTED_METHOD = 'error.batchOutput.methodNotSupported',
    BATCH_OUTPUT_UNSUPPORTED_TYPE = 'error.batchOutput.typeNotSupported',
    BATCH_OUTPUT_UNSUPPORTED_MODEL = 'error.batchOutput.modelNotSupported',
    BATCH_DOCUMENT_NOT_FOUND = 'error.batchDocument.notFound',
    DOCUMENT_NOT_FOUND = 'error.s3Storage.downloadError',
    DOCUMENT_NOT_CONVERTED = 'error.fileConversion.failedToConvertToBase64',
    CREDIT_FLOW_FAILED_TO_CREATE_DOCUMENT = 'error.creditFlow.failedToCreateDocument',
    CREDIT_FLOW_FAILED_TO_READY_DOCUMENT_FOR_SIGNATURE = 'error.creditFlow.failedToReadyDocumentForSignature',
    CREDIT_FLOW_ERROR = 'error.creditFlow.integrationError',
}

export enum GTBankerCessionOutputs {
    BATCH_EXPORT_NOT_FOUND = 'error.batchExport.notFound',
    BATCH_OUTPUT_METHOD_NOT_SUPPORTED = 'error.batchOutput.methodNotSupported',
    BATCH_OUTPUT_MISSING_PLATFORM = 'error.batchOutput.missingPlatform',
    BATCH_OUTPUT_MODEL_NOT_SUPPORTED = 'error.batchOutput.modelNotSupported',
    BATCH_OUTPUT_NOT_FOUND = 'error.batchOutput.notFound',
    BATCH_OUTPUT_PLATFORM_NOT_SUPPORTED = 'error.batchOutput.platformNotSupported',
    BATCH_OUTPUT_TYPE_NOT_SUPPORTED = 'error.batchOutput.typeNotSupported',
    CHUNK_ITEM_NOT_FOUND = 'error.chunkItem.notFound',
    CHUNK_STREAM_ALREADY_EXPORTED = 'error.chunkStream.alreadyExported',
    CHUNK_STREAM_ALREADY_EXISTS = 'error.chunkStream.alreadyExists',
    CHUNK_STREAM_NOT_FOUND = 'error.chunkStream.notFound',
    CHUNK_STREAM_TYPE_NOT_SUPPORTED = 'error.chunkStream.typeNotSupported',
    CHUNK_STREAM_STRATEGY_NOT_FOUND = 'error.chunkStream.strategyNotFound',
    CHUNK_STREAM_STRATEGY_NOT_SUPPORTED = 'error.chunkStream.strategyNotSupported',
    DATA_CHUNK_ALREADY_EXPORTED = 'error.dataChunk.alreadyExported',
    DATA_CHUNK_NOT_FOUND = 'error.dataChunk.notFound',
    DATA_CHUNK_TYPE_NOT_SUPPORTED = 'error.dataChunk.typeNotSupported',
    DATA_CHUNK_STRATEGY_NOT_FOUND = 'error.dataChunk.strategyNotFound',
    DATA_CHUNK_STRATEGY_NOT_SUPPORTED = 'error.dataChunk.strategyNotSupported',
    EXPORT_REQUEST_EXPORT_FAILURE = 'error.exportRequest.exportFailure',
    EXPORT_REQUEST_NOT_FOUND = 'error.exportRequest.notFound',
    EXPORT_REQUEST_TYPE_NOT_SUPPORTED = 'error.exportRequest.typeNotSupported',
    EXPORTED_REQUEST_ALREADY_PROCESSED = 'error.exportRequest.alreadyProcessed',
    EXPORTED_REQUEST_ALREADY_SEND = 'error.exportRequest.alreadySend',
    ISSUER_REFERENCE_ABSENT = 'error.issuer.referenceAbsent',
    ISSUER_INTEGRATION_AUTHENTICATION_NOT_SUPPORTED = 'error.issuerIntegration.authenticationNotSupported',
    ISSUER_INTEGRATION_MISSING_AUTHENTICATION_TOKEN = 'error.issuerIntegration.missingAuthenticationToken',
}

const errorsCode: string[] = [
    ...new Set([
        ...Object.values(GiroScdErrorConstants),
        ...Object.values(GTBankerSignDocuments),
        ...Object.values(GTBankerCessionOutputs),
    ]),
];

export const hasMappedErrorCode = (error: string): boolean => {
    return errorsCode.some(errorCode => errorCode === error);
};

export type ErrorConstants = GiroScdErrorConstants | GiroWebappErrorConstants | GTBankerSignDocuments | GTBankerCessionOutputs;
