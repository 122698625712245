import { Checkbox, Menu, MenuItem, PopoverOrigin } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import clsx from 'clsx';
import DateFormatter from 'components/formatter/date/date-formatter';
import IdentificationFormatter from 'components/formatter/identification/identification-formatter';
import { OperationsModalMessageActions } from 'entities/ccb-operation/ccb-operation';
import { ImportedOperationStatus } from 'model/enums/imported-operation-status';
import { OperationDocumentType } from 'model/enums/operation-document-type';
import { PersonType } from 'model/enums/person-type';
import { OperationFilterRequest } from 'model/imported-operation';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImportedOperationsListState, useRootDispatch } from 'reducer/hooks';
import { importedOperationDocumentRequest } from 'reducer/imported-operation/document/actions';
import { useToast } from 'shared/hooks/use-toast';
import { valuesOfObject } from 'shared/util/object-utils';
import MoreOptionsMenu from 'components/more-options-menu/options-menu/options-menu';
import OperationsMoreOptionsMenuItems from 'entities/ccb-operation/components/operations-more-options-menu-items/operations-more-options-menu-items';

const I18N_PREFIX = 'pages.scd.ccb-operation';

interface OperationsListProps {
    showCheckBox: boolean;
    isSelectedAllCheckbox: boolean;
    setSelectedOperations: (value: number[]) => void;
    selectedOperations: number[];
    filterActive: OperationFilterRequest;
    activeOperationStatus: ImportedOperationStatus | undefined;
    setActiveOperationStatus: (status: ImportedOperationStatus | undefined) => void;
    setModalMessage: (value: OperationsModalMessageActions) => void;
    page: number;
}

const OperationsList = (props: OperationsListProps) => {
    const {
        showCheckBox,
        isSelectedAllCheckbox,
        selectedOperations,
        setSelectedOperations,
        filterActive,
        setActiveOperationStatus,
        activeOperationStatus,
        setModalMessage,
        page,
    } = props;
    const { t } = useTranslation();

    const { operations } = useImportedOperationsListState();

    const [anchorMenuEl, setAnchorMenuEl] = useState<null | HTMLElement>(null);
    const [anchorContractMenuEl, setAnchorContactMenuEl] = useState<null | HTMLElement>(null);

    const [selectedOperationHasBatch, setSelectedOperationHasBatch] = useState<boolean>(false);
    const [hasOriginalContract, setHasOriginalContract] = useState<boolean>(false);
    const [hasSignedContract, setHasSignedContract] = useState<boolean>(false);

    const dispatch = useRootDispatch();
    const { toastInfo } = useToast();

    const [operationId, setOperationId] = useState<number | undefined>(undefined);
    const [ccbNumber, setCcbNumber] = useState<number | undefined>(undefined);

    const openContractMenu = Boolean(anchorContractMenuEl);

    useEffect(() => {
        if (isSelectedAllCheckbox) {
            const filteredData: number[] | undefined = operations?.content
                ?.filter(({ hasBatch, status }) => (status !== ImportedOperationStatus.EXPORTED ? !hasBatch : true))
                .map(({ id }) => id);
            setActiveOperationStatus(undefined);

            if (!filteredData) return;

            setSelectedOperations(filteredData);
        }
    }, [isSelectedAllCheckbox, operations, setSelectedOperations, setActiveOperationStatus]);

    useEffect(() => {
        if (page) {
            setSelectedOperations([]);
        }
    }, [page, setSelectedOperations]);

    const hasSelectedOperation = useCallback(
        (id: number): boolean => selectedOperations.some(operationId => operationId === id),
        [selectedOperations]
    );

    const handleChange = (id: number, status: ImportedOperationStatus) => {
        if (isSelectedAllCheckbox) return;
        if (!!activeOperationStatus && activeOperationStatus !== status) return;

        if (hasSelectedOperation(id)) {
            const filteredData: number[] = selectedOperations.filter(operationId => operationId !== id);

            if (filteredData.length === 0) {
                setActiveOperationStatus(undefined);
            }

            setSelectedOperations(filteredData);
            return;
        }

        setActiveOperationStatus(status);
        setSelectedOperations([...selectedOperations, id]);
    };

    const verifyCheckedCheckbox = useCallback(
        (id: number, hasBatch: boolean): boolean => {
            if (!showCheckBox) return false;
            if (hasBatch && filterActive.status?.every(status => status === ImportedOperationStatus.EXPORTED) && isSelectedAllCheckbox)
                return isSelectedAllCheckbox;
            if (hasBatch) return hasSelectedOperation(id);
            if (isSelectedAllCheckbox) return isSelectedAllCheckbox;

            return hasSelectedOperation(id);
        },
        [isSelectedAllCheckbox, hasSelectedOperation, filterActive, showCheckBox]
    );

    const handleCloseMenu = () => {
        setAnchorMenuEl(null);
    };

    const handleClickContractMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorContactMenuEl(event.currentTarget);
    };

    const handleCloseContractMenu = () => {
        setAnchorContactMenuEl(null);
    };

    const handleGenerateContract = (type: OperationDocumentType) => {
        if (!operationId) return;
        if (!ccbNumber) return;

        dispatch(importedOperationDocumentRequest({ operationId, type, ccbNumber }));
    };

    const transformOrigin: PopoverOrigin = {
        vertical: -40,
        horizontal: 'left',
    };

    const anchorOrigin: PopoverOrigin = {
        vertical: 'top',
        horizontal: 'center',
    };

    const canBeArchived =
        activeOperationStatus === ImportedOperationStatus.NEW ||
        activeOperationStatus === ImportedOperationStatus.BATCHLESS ||
        activeOperationStatus === ImportedOperationStatus.PRE_PROCESS_ERROR ||
        activeOperationStatus === ImportedOperationStatus.DEFINE_FLOW_ERROR ||
        activeOperationStatus === ImportedOperationStatus.DEFINE_PAYMENT_ERROR ||
        activeOperationStatus === ImportedOperationStatus.AUTOMATIC_PAYMENT_ERROR;

    const isTableRowDisabled = useCallback(
        (hasBatch: boolean, status: ImportedOperationStatus): boolean => {
            return showCheckBox
                ? (hasBatch && status === ImportedOperationStatus.ERROR) ||
                      (hasBatch && status === ImportedOperationStatus.NEW) ||
                      (!!activeOperationStatus && activeOperationStatus !== status)
                : false;
        },
        [activeOperationStatus, showCheckBox]
    );

    const shouldApplyErrorClassname = useCallback((status: ImportedOperationStatus): boolean => {
        return (
            status === ImportedOperationStatus.ERROR ||
            status === ImportedOperationStatus.OPERATION_DATA_ERROR ||
            status === ImportedOperationStatus.PRE_PROCESS_ERROR ||
            status === ImportedOperationStatus.DEFINE_FLOW_ERROR ||
            status === ImportedOperationStatus.DEFINE_PAYMENT_ERROR ||
            status === ImportedOperationStatus.AUTOMATIC_PAYMENT_ERROR
        );
    }, []);

    const isDisabledCheckbox = useCallback(
        (hasBatch: boolean, status: ImportedOperationStatus): boolean => {
            return (
                (hasBatch && status === ImportedOperationStatus.ERROR) ||
                (hasBatch && status === ImportedOperationStatus.NEW) ||
                (!!activeOperationStatus && activeOperationStatus !== status)
            );
        },
        [activeOperationStatus]
    );

    const showActiveContractMenu = useCallback(
        (id: number): boolean => {
            return id === operationId && openContractMenu;
        },
        [operationId, openContractMenu]
    );

    return (
        <>
            {operations?.content?.length !== 0 ? (
                operations?.content?.map(
                    ({
                        id,
                        ccbNumber,
                        acceptedDate,
                        product,
                        batchCode,
                        status,
                        originator,
                        loanerIdentification,
                        hasBatch,
                        hasOriginalContract,
                        hasSignedContract,
                    }) => (
                        <tr
                            className={clsx('page-container--table-cell', {
                                isSelected: verifyCheckedCheckbox(id, hasBatch),
                                disabled: isTableRowDisabled(hasBatch, status),
                            })}
                            key={id}
                        >
                            <td className="page-container--table-cell-title">
                                {ccbNumber ?? "-"}
                            </td>
                            <td className="page-container--table-cell-title">
                                {hasBatch ? batchCode : <span className="batchless"> {t(`${I18N_PREFIX}.batchless`).toUpperCase()} </span>}
                            </td>
                            <td className="page-container--table-cell-title">
                                {acceptedDate ? <DateFormatter date={acceptedDate} /> : '00/00/00'}
                            </td>
                            <td className="page-container--table-cell-title">
                                <span> {t(`${I18N_PREFIX}.product.options.${product}`).toUpperCase()}</span>
                            </td>
                            <td className="page-container--table-cell-title">
                                {originator ?? <> {t(`${I18N_PREFIX}.no-originator`)} </>}
                            </td>
                            <td className="page-container--table-cell-title">
                                <IdentificationFormatter value={loanerIdentification} type={PersonType.CORPORATION} />
                            </td>
                            <td className="page-container--table-cell-title">
                                <span className="page-container--table-status">
                                    <span
                                        className={clsx('page-container--table-status', {
                                            RECEIVED: status === ImportedOperationStatus.RECEIVED,
                                            PENDING_APPROVAL: status === ImportedOperationStatus.PENDING_APPROVAL,
                                            APPROVED: status === ImportedOperationStatus.APPROVED,
                                            NEW: status === ImportedOperationStatus.NEW,
                                            BATCHLESS: status === ImportedOperationStatus.BATCHLESS,
                                            EXPORTED: status === ImportedOperationStatus.EXPORTED,
                                            ARCHIVED: status === ImportedOperationStatus.ARCHIVED,
                                            DISAPPROVED: status === ImportedOperationStatus.DISAPPROVED,
                                            ERROR: shouldApplyErrorClassname(status),
                                        })}
                                    >
                                        {t(`${I18N_PREFIX}.status.options.${status}`).toUpperCase()}
                                    </span>
                                </span>
                            </td>
                            <td className="page-container--table-cell-title">
                                {showActiveContractMenu(id) ? (
                                    <div className="icon-container-active">
                                        <span className="icon-container-active__contract" />
                                        <ArrowDropUpIcon fontSize="small" />
                                    </div>
                                ) : (
                                    <div
                                        className="icon-container"
                                        id="long-button"
                                        aria-controls="long-menu"
                                        onClick={e => {
                                            if (!hasOriginalContract && !hasSignedContract) {
                                                toastInfo(t(`${I18N_PREFIX}.toast.contract.error`));
                                                return;
                                            }

                                            handleClickContractMenu(e);
                                            setOperationId(id);
                                            setHasOriginalContract(hasOriginalContract);
                                            setHasSignedContract(hasSignedContract);
                                            setCcbNumber(ccbNumber);
                                        }}
                                    >
                                        <span className="icon-container__contract" />
                                        <ArrowDropDownIcon fontSize="small" />
                                    </div>
                                )}
                            </td>

                            <td className="page-container--table-cell-icon">
                                {showCheckBox ? (
                                    <Checkbox
                                        checked={verifyCheckedCheckbox(id, hasBatch)}
                                        onChange={() => {
                                            handleChange(id, status);
                                        }}
                                        disabled={isDisabledCheckbox(hasBatch, status)}
                                    />
                                ) : (
                                    <MoreOptionsMenu
                                        anchorEl={anchorMenuEl}
                                        setAnchorEl={setAnchorMenuEl}
                                        onClick={() => {
                                            setSelectedOperations([id]);
                                            setActiveOperationStatus(status);
                                            setOperationId(id);
                                            setSelectedOperationHasBatch(hasBatch);
                                        }}
                                    >
                                        <OperationsMoreOptionsMenuItems
                                            activeOperationStatus={activeOperationStatus}
                                            canBeArchived={canBeArchived}
                                            handleCloseMenu={handleCloseMenu}
                                            hasBatch={selectedOperationHasBatch}
                                            operationId={operationId}
                                            setModalMessage={setModalMessage}
                                        />
                                    </MoreOptionsMenu>
                                )}
                            </td>
                        </tr>
                    )
                )
            ) : (
                <div className="scd-ccb-operation__table-empty-filter">
                    <span className="scd-ccb-operation__table-empty-filter-image" />
                    <span className="scd-ccb-operation__table-empty-filter-title">{t(`${I18N_PREFIX}.not-found`)}</span>
                    <span className="scd-ccb-operation__table-empty-filter-subtitle">
                        {valuesOfObject({ ...filterActive }).length !== 0 && t(`${I18N_PREFIX}.invalid-filter`)}
                    </span>
                </div>
            )}
            <Menu
                anchorEl={anchorContractMenuEl}
                open={openContractMenu}
                onClose={handleCloseContractMenu}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
            >
                {hasOriginalContract && (
                    <MenuItem
                        onClick={() => {
                            handleCloseContractMenu();
                            handleGenerateContract(OperationDocumentType.ORIGINAL_CONTRACT);
                        }}
                    >
                        {t(`${I18N_PREFIX}.menu.original-contract`)}
                    </MenuItem>
                )}
                {hasSignedContract && (
                    <MenuItem onClick={() => handleGenerateContract(OperationDocumentType.SIGNED_CONTRACT)}>
                        {t(`${I18N_PREFIX}.menu.signed-contract`)}
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default OperationsList;
