import { ReactNode, useState } from 'react';

// TODO: ADJUST I18N PATH
const I18N_PREFIX = '';

enum GTSecIntegrationSteps {
    UNDEFINED = 'UNDEFINED',
}

export const useFooterComponents = () => {
    const [footerType, setFooterType] = useState<GTSecIntegrationSteps>(GTSecIntegrationSteps.UNDEFINED);

    const EmptyFooter: ReactNode = <></>;

    const renderFooterContent = {
        UNDEFINED: EmptyFooter,
    } as Record<GTSecIntegrationSteps, ReactNode>;

    const currentFooter = renderFooterContent[footerType];

    return { currentFooter };
};
