import { FC } from 'react';

import { useFooterComponents } from 'entities/batch/batch-outputs-detail/components/webcred/components/cession-integration-webcred-footer/hooks/use-footer-components';
import './cession-integration-webcred-footer.scss';

// TODO: ADJUST I18N PATH
const I18N_PREFIX = '';

interface StandardFooterProps {}

const CessionIntegrationWebcredFooter: FC<StandardFooterProps> = () => {
    const { currentFooter } = useFooterComponents();

    return <>{currentFooter}</>;
};

export default CessionIntegrationWebcredFooter;
