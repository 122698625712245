import clsx from 'clsx';
import DateFormatter from 'components/formatter/date/date-formatter';
import BatchDetailHeader from 'entities/batch/components/batch-detail-header/batch-detail-header';
import BatchDetailHeaderContent from 'entities/batch/components/batch-detail-header-content/batch-detail-header-content';
import BatchListCard from 'entities/batch/batch-list-card/batch-list-card';
import { useBatchDetailWithProgramState } from 'reducer/hooks';
import { useTranslation } from 'react-i18next';
import { HttpRequestStatus } from 'model/enums/http-request-status';
import Loading from 'components/loading/loading';
import { compose } from 'redux';
import { withResumeBatchWrapper } from 'shared/provider/batchContext';
import './batch-detail.scss';

const I18N_PREFIX = 'pages.scd.batch';

const BatchDetail = () => {
    const { t } = useTranslation();

    const { batchWithProgram, status: batchWithProgramStatus } = useBatchDetailWithProgramState();

    const isLoadingBatch = batchWithProgramStatus !== HttpRequestStatus.SUCCESS;

    return (
        <main className="batch-detail">
            <section className="batch-detail__container">
                <h1 className="batch-detail__container-title">{t(`${I18N_PREFIX}.batch-card.titles.batch-detail`)}</h1>
                {isLoadingBatch ? (
                    <Loading />
                ) : (
                    <>
                        <BatchDetailHeader>
                            <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.batch`)}>
                                {batchWithProgram?.code ?? '-'}
                            </BatchDetailHeaderContent>
                            <BatchDetailHeaderContent
                                title={t(`${I18N_PREFIX}.batch-card.titles.type`)}
                                className="header-content__type-content"
                            >
                                {t(`${I18N_PREFIX}.product.options.${batchWithProgram?.product}`).toUpperCase()}
                            </BatchDetailHeaderContent>
                            <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.originator`)} isTooltip>
                                {batchWithProgram?.originator?.name ?? '-'}
                            </BatchDetailHeaderContent>
                            <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.batch-date`)}>
                                {batchWithProgram?.date ? <DateFormatter date={batchWithProgram?.date} /> : '-'}
                            </BatchDetailHeaderContent>
                            <BatchDetailHeaderContent title={t(`${I18N_PREFIX}.batch-card.titles.status`)} isTooltip>
                                <span className={clsx('batch-detail__container-status', batchWithProgram?.status)}>
                                    {t(`${I18N_PREFIX}.status.options.${batchWithProgram?.status}`).toUpperCase()}
                                </span>
                            </BatchDetailHeaderContent>
                        </BatchDetailHeader>

                        <BatchListCard />
                    </>
                )}
            </section>
        </main>
    );
};

export default compose(withResumeBatchWrapper())(BatchDetail);
