import { AxiosResponse } from 'axios';
import HttpStatus from 'model/enums/http-status';
import { markError } from 'reducer/application/error/actions';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import batchOutputBcoApi from 'services/bco/batch-outputs-bco';
import ErrorUtils from 'shared/error/error-utils';
import {
    listCessionOperationBcoError,
    listCessionOperationBcoRequest,
    listCessionOperationBcoSuccess,
} from 'reducer-bco/batch-output/cession-operation/action';
import { CessionOperationBco } from 'model/bco/batch-output';
import { CessionOperationBcoTypes } from 'reducer-bco/batch-output/cession-operation/types';
import { Page } from 'services/page';

function* handleCessionOperationBco(action: ReturnType<typeof listCessionOperationBcoRequest>) {
    try {
        const result: AxiosResponse<Page<CessionOperationBco>> = yield call(batchOutputBcoApi.cessionOperation, action.payload);
        if (result.status !== HttpStatus.OK) {
            const mapped = ErrorUtils.mapGiroScdError(result);
            yield put(markError(mapped));
            yield put(listCessionOperationBcoError(mapped));
            return;
        }
        yield put(listCessionOperationBcoSuccess(result.data));
    } catch (error) {
        const mapped = ErrorUtils.mapLocalError(error);
        yield put(markError(mapped));
        yield put(listCessionOperationBcoError(mapped));
    }
}

function* watchCessionOperationBcoSaga() {
    yield takeEvery(CessionOperationBcoTypes.CESSION_OPERATION_BCO_REQUEST, handleCessionOperationBco);
}

function* cessionOperationBcoSaga() {
    yield all([fork(watchCessionOperationBcoSaga)]);
}

export default cessionOperationBcoSaga;
