import { BaseStoreState } from 'reducer/base-store-state';
import { CessionOperationBco } from 'model/bco/batch-output';
import {Page} from "services/page";

export enum CessionOperationBcoTypes {
    CESSION_OPERATION_BCO_REQUEST = '@@cession-operation-bco/CESSION_OPERATION_BCO_REQUEST',
    CESSION_OPERATION_BCO_SUCCESS = '@@cession-operation-bco/CESSION_OPERATION_BCO_SUCCESS',
    CESSION_OPERATION_BCO_ERROR = '@@cession-operation-bco/CESSION_OPERATION_BCO_ERROR',
    CESSION_OPERATION_BCO_RESET = '@@cession-operation-bco/CESSION_OPERATION_BCO_RESET',
}

export interface CessionOperationBcoState extends BaseStoreState {
    readonly listCessionOperation?: Page<CessionOperationBco>;
}
