import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { ClearButton } from 'components/buttons/clear-button/clear-button';
import CloseButton from 'components/buttons/close-button/close-button';
import StandardButtonLegacy from 'components/buttons/standard-button-legacy/standard-button-legacy';
import ListSearchInput from 'components/inputs/list-search-input/list-search-input';
import { SEARCH_ITEM_SIZE_LARGE } from 'components/modals/constants';
import { BatchFilterRequest } from 'model/batch';
import { ScdCompanySearch } from 'model/company';
import { BatchStatus } from 'model/enums/batch-status';
import { ProductType } from 'model/enums/product-type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { IRootState } from 'reducer';
import { searchCompaniesRequest } from 'reducer/company/search-companies/actions';
import './batch-modal-filter.scss';

export type BatchModalFilterType = 'product' | 'originator' | 'status';

export interface BatchModalFilterProps {
    onClose: () => void;
    modalFilter: BatchModalFilterType;
    openModal: boolean;
    title: string;
    filterActive: BatchFilterRequest;
    setFilterActive: (value: BatchFilterRequest) => void;
    setPage: (value: number) => void;
}

const I18N_PREFIX = 'pages.scd.batch';

export const DISABLED_MODAL_BUTTON_FUNCTION_RECORD = {
    originator: filter => !filter.originator,
    status: filter => !filter.status,
    product: filter => !filter.product,
} as Record<BatchModalFilterType, (filter: BatchFilterRequest) => boolean>;

export const BatchModalFilter = (props: BatchModalFilterProps) => {
    const { onClose, modalFilter, openModal, title, setFilterActive, filterActive, setPage } = props;

    const [filterRequest, setFilterRequest] = useState<BatchFilterRequest>(filterActive);

    const { t } = useTranslation();

    useEffect(() => {
        setFilterRequest(filterActive);
    }, [filterActive]);

    if (!openModal) {
        return <></>;
    }

    const handleConfirmRequest = () => {
        setPage(0);
        setFilterActive(filterRequest);
        onClose();
    };

    const handleChange = (value: Partial<BatchFilterRequest>) => {
        setFilterRequest({ ...filterRequest, ...value });
    };

    const handleClearFilter = {
        product: () => {
            setFilterRequest({ ...filterRequest, product: undefined });
            setFilterActive({ ...filterActive, product: undefined });
        },
        originator: () => {
            setFilterRequest({ ...filterRequest, originator: undefined });
            setFilterActive({ ...filterActive, originator: undefined });
        },
        status: () => {
            setFilterRequest({ ...filterRequest, status: undefined });
            setFilterActive({ ...filterActive, status: undefined });
        },
    } as Record<BatchModalFilterType, () => void>;

    const isConfirmButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterRequest);
    const isCleanButtonDisabled = DISABLED_MODAL_BUTTON_FUNCTION_RECORD[modalFilter](filterActive);

    return (
        openModal && (
            <Modal isOpen>
                <div className="batch-modal-filter">
                    <div className="batch-modal-filter--header">
                        <label className="batch-modal-filter--header-title"> {title} </label>
                        <CloseButton onClick={onClose} />
                    </div>
                    <Grid container spacing={2}>
                        {
                            (
                                {
                                    status: (
                                        <>
                                            <div className="batch-modal-filter--content">
                                                <div
                                                    className={clsx('batch-modal-filter--content-status--label', {
                                                        OPEN: filterRequest.status === BatchStatus.OPEN,
                                                    })}
                                                    onClick={() => handleChange({ status: BatchStatus.OPEN })}
                                                >
                                                    {t(`${I18N_PREFIX}.status.options.OPEN`).toLocaleUpperCase()}
                                                </div>

                                                <div
                                                    className={clsx('batch-modal-filter--content-status--label', {
                                                        CONCLUDED: filterRequest.status === BatchStatus.CONCLUDED,
                                                    })}
                                                    onClick={() => handleChange({ status: BatchStatus.CONCLUDED })}
                                                >
                                                    {t(`${I18N_PREFIX}.status.options.CONCLUDED`).toLocaleUpperCase()}
                                                </div>

                                                <div
                                                    className={clsx('batch-modal-filter--content-status--label', {
                                                        CLOSED: filterRequest.status === BatchStatus.CLOSED,
                                                    })}
                                                    onClick={() => handleChange({ status: BatchStatus.CLOSED })}
                                                >
                                                    {t(`${I18N_PREFIX}.status.options.CLOSED`).toLocaleUpperCase()}
                                                </div>

                                                <div
                                                    className={clsx('batch-modal-filter--content-status--label', {
                                                        ERROR: filterRequest.status === BatchStatus.ERROR,
                                                    })}
                                                    onClick={() => handleChange({ status: BatchStatus.ERROR })}
                                                >
                                                    {t(`${I18N_PREFIX}.status.options.ERROR`).toLocaleUpperCase()}
                                                </div>

                                                <div
                                                    className={clsx('batch-modal-filter--content-status--label', {
                                                        PROCESSING: filterRequest.status === BatchStatus.PROCESSING,
                                                    })}
                                                    onClick={() => handleChange({ status: BatchStatus.PROCESSING })}
                                                >
                                                    {t(`${I18N_PREFIX}.status.options.PROCESSING`).toLocaleUpperCase()}
                                                </div>
                                            </div>
                                        </>
                                    ),
                                    product: (
                                        <>
                                            <div className="batch-modal-filter--content">
                                                <div
                                                    className={clsx('batch-modal-filter--content-product--label', {
                                                        active: filterRequest.product === ProductType.CREDIT_CARD,
                                                    })}
                                                    onClick={() => handleChange({ product: ProductType.CREDIT_CARD })}
                                                >
                                                    {t(`${I18N_PREFIX}.product.options.CREDIT_CARD`).toLocaleUpperCase()}
                                                </div>

                                                <div
                                                    className={clsx('batch-modal-filter--content-product--label', {
                                                        active: filterRequest.product === ProductType.DIRECT_CONSUMER_CREDIT,
                                                    })}
                                                    onClick={() => handleChange({ product: ProductType.DIRECT_CONSUMER_CREDIT })}
                                                >
                                                    {t(`${I18N_PREFIX}.product.options.DIRECT_CONSUMER_CREDIT`).toLocaleUpperCase()}
                                                </div>

                                                <div
                                                    className={clsx('batch-modal-filter--content-product--label', {
                                                        active: filterRequest.product === ProductType.PERSONAL_CREDIT,
                                                    })}
                                                    onClick={() => handleChange({ product: ProductType.PERSONAL_CREDIT })}
                                                >
                                                    {t(`${I18N_PREFIX}.product.options.PERSONAL_CREDIT`).toLocaleUpperCase()}
                                                </div>
                                            </div>
                                        </>
                                    ),
                                    originator: (
                                        <>
                                            <Grid item xs={12}>
                                                <Grid item xs={12}>
                                                    <ListSearchInput<ScdCompanySearch>
                                                        action={searchCompaniesRequest}
                                                        itemSize={SEARCH_ITEM_SIZE_LARGE}
                                                        onSelect={company =>
                                                            handleChange({
                                                                originator: {
                                                                    id: company?.id,
                                                                    name: company?.name,
                                                                    identification: company?.identification,
                                                                },
                                                            })
                                                        }
                                                        renderItem={company => `${company?.identification} ${company?.name}`}
                                                        statusSelector={(state: IRootState) => state.searchCompanies.status}
                                                        dataSelector={(state: IRootState) => state.searchCompanies.companies}
                                                        label={t(`${I18N_PREFIX}.originator`)}
                                                        placeholder={t(`${I18N_PREFIX}.originator`)}
                                                        value={
                                                            filterRequest?.originator?.identification && filterRequest?.originator?.name
                                                                ? `${filterRequest.originator.identification} ${filterRequest.originator.name}`
                                                                : ''
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </>
                                    ),
                                } as Record<BatchModalFilterType, React.ReactNode>
                            )[modalFilter]
                        }
                    </Grid>

                    <div className="batch-modal-filter--buttons-actions">
                        <ClearButton onClick={handleClearFilter[modalFilter]} disabled={isCleanButtonDisabled} />
                        <StandardButtonLegacy
                            label="entity.action.confirm"
                            onClick={handleConfirmRequest}
                            disabled={isConfirmButtonDisabled}
                        />
                    </div>
                </div>
            </Modal>
        )
    );
};

export default BatchModalFilter;
