import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';

// BatchOutput
import { CessionToResumeBcoState } from 'reducer-bco/batch-output/cession-to-resume/types';
import { ChunkCessionFinalizeBcoState } from 'reducer-bco/batch-output/chunk-cession-finalize/types';
import { ChunkStreamPartitioningBcoState } from 'reducer-bco/batch-output/chunk-stream-partitioning/types';
import { ChunkStreamProcessBcoState } from 'reducer-bco/batch-output/chunk-stream-process/types';
import { ChunkStreamToResumeBcoState } from 'reducer-bco/batch-output/chunk-stream-to-resume/types';
import { BatchOutputsDetailsBcoState } from 'reducer-bco/batch-output/details-batch-output/types';
import { BatchOutputsListBcoState } from 'reducer-bco/batch-output/list-batch-output/types';
import { ChunkStreamRetryBcoState } from 'reducer-bco/batch-output/retry-chunk-stream/types';
import { DataChunkRetryBcoState } from 'reducer-bco/batch-output/retry-data-chunk/types';
import {CessionOperationBcoState} from "reducer-bco/batch-output/cession-operation/types";

// BatchOutput List
export const useBatchOutputsListBco = () =>
    useSelector<IRootState, BatchOutputsListBcoState>((state: IRootState) => state.listBatchOutputs);

// BatchOutput Details
export const useBatchOutputDetailsBco = () =>
    useSelector<IRootState, BatchOutputsDetailsBcoState>((state: IRootState) => state.detailsBatchOutput);

// ChunkStreamToResume
export const useChunkStreamToResumeBco = () =>
    useSelector<IRootState, ChunkStreamToResumeBcoState>((state: IRootState) => state.chunkStreamToResume);

// ChunkStreamPartitioning
export const useChunkStreamPartitioningBco = () =>
    useSelector<IRootState, ChunkStreamPartitioningBcoState>((state: IRootState) => state.chunkStreamPartitioning);

//CessionToResume
export const useCessionToResumeBco = () => useSelector<IRootState, CessionToResumeBcoState>((state: IRootState) => state.cessionToResume);

// ChunkStreamProcess
export const useChunkStreamProcessBco = () =>
    useSelector<IRootState, ChunkStreamProcessBcoState>((state: IRootState) => state.chunkStreamProcess);

// ChunkStreamProcess
export const useChunkCessionFinalizeBco = () =>
    useSelector<IRootState, ChunkCessionFinalizeBcoState>((state: IRootState) => state.chunkCessionFinalize);

// DataChunkRetry
export const useDataChunkRetryBco = () => useSelector<IRootState, DataChunkRetryBcoState>((state: IRootState) => state.dataChunkRetry);

// ChunkStreamRetry
export const useChunkStreamRetryBco = () =>
    useSelector<IRootState, ChunkStreamRetryBcoState>((state: IRootState) => state.chunkStreamRetry);

// CessionOperation
export const useCessionOperationBco = () =>
    useSelector<IRootState, CessionOperationBcoState>((state: IRootState) => state.listCessionOperation);